// import Swiper JS
import Swiper from 'swiper'
// import Swiper styles
// import 'swiper/swiper.scss'

contactClick()
// setTimeout( function() {
initSlider()
// }, 500)

function contactClick() {
  let hamburger = document.querySelector('.hamburger')
  let logo = document.querySelector('.logo')
  let contact = document.querySelector('.contact')
  let close = document.querySelector('.close')

  hamburger.addEventListener('click', (e) => {
    contact.classList.remove('hidden')
    logo.classList.add('hidden')
  })

  close.addEventListener('click', (e) => {
    logo.classList.remove('hidden')
    contact.classList.add('hidden')
  })
}

function initSlider(){
  let slideshow = document.querySelector('.block-gallery.desktop')
  let slideshowMobile = document.querySelector('.block-gallery.mobile')

  if(slideshow){
    const swiper = new Swiper(slideshow, {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      centeredSlidesBounds: true,
    })
    swiper.on('click', function() {
      swiper.slideNext()
    })
    const swiperMobile = new Swiper(slideshowMobile, {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      centeredSlidesBounds: true,
    })
  }
}
